import Send from '../../trans.client.js'

//주석정의방법 : 컨트롤러 메소드명 - 메소드에 대한 간단 주석 - REST URI
export default {
  //RF 위험물 사전승인번호 선택 리스트
  getRfDgRequestList (param) {
      return Send({
          url: '/trans/rf-dg/rf-dg-request',
          method: 'get',
          params: {
            polPortCd: param.polPortCd,
            podPortCd: param.podPortCd
        }
      })
  },
  //RF 위험물 사전승인 요청 리스트
  getRfDgList (param) {
    return Send({
        // url: 'http://localhost:9006/trans/rfdg/list',
        url: '/trans/rfdg/list',
        method: 'get',
        params: {
            fromDt: param.asFromDt,
            toDt: param.asToDt,
            dtKnd: param.asDtKnd,
            reqNo1: param.asReqNo1,
            reqNo2: param.asReqNo2,
            reqNo3: param.asReqNo3,
            polPlcCd: param.asPolPlcCd,
            podPlcCd: param.asPodPlcCd,
            chk1: param.asChk1,
            chk2: param.asChk2,
            chk3: param.asChk3,
            chk4: param.asChk4
        }
    })
  },
  getRfDgInfo (param) {
    return Send({
        //url: 'http://localhost:9006/trans/rfdg/info',
        url: '/trans/rfdg/info',
        method: 'get',
        params: {
            reqNo: param
        }
    })
  },
  insertRfDg (data) {
    return new Promise((resolve, reject) => {
        Send({
            //url: 'http://localhost:9006/trans/rfdg/req',
            url: '/trans/rfdg/req',
            method: 'PUT',
            data: data
        }).then(res => {
            resolve(res)
        }).catch((err) => {
            reject(err)
        })
    })
  },
  getCstInfo () {
    return Send({
        //url: 'http://localhost:9006/trans/rfdg/cst',
        url: '/trans/rfdg/cst',
        method: 'get'
    })
  },
  getFileList (param) {
    return Send({
        //url: 'http://localhost:9006/trans/rfdg/file',
        url: '/trans/rfdg/file',
        method: 'get',
        params: param
    })
  },
  getFileInfo (param) {
    return Send({
        //url: 'http://localhost:9006/trans/rfdg/finfo',
        url: '/trans/rfdg/finfo',
        method: 'get',
        params: param
    })
  },
  getRfdata (param) {
    return Send({
        //url: 'http://localhost:9006/trans/rf-dg/rf-data',
        url: '/trans/rf-dg/rf-data',
        method: 'get',
        params: {
          cntrNo: param
        }
    })
  },
  getRfdataCnt (param) {
    return Send({
        //url: 'http://localhost:9006/trans/rf-dg/rf-data',
        url: '/trans/rf-dg/rf-data-cnt',
        method: 'get',
        params: {
          cntrNo: param
        }
    })
  },
  getRfTarget (param) {
    return Send({
        //url: 'http://localhost:9006/trans/rf-dg/rf-data',
        url: '/trans/rf-dg/rf-data-target',
        method: 'get'
        // params: {
        //   cntrNo: param
        // }
    })
  },
  getRfVslLocation (params) {
    return Send({
        //url: 'http://localhost:9006/trans/rf-dg/rf-data',
        url: '/trans/rf-dg/rf-data-vsl-location',
        method: 'get',
        params: {
          vslVoyStr: params.covParams,
          blNo: params.blNo
        }
    })
  },
  // getVesselPreiousRoute - CP > 선박 지나온 루트 정보 - GET /trans/rfdg/previous-route
  getVesselPreiousRoute (params) {
    return Send({
      url: '/trans/rf-dg/previous-route',
      method: 'get',
      params: params
    })
  },
  // getVesselPreiousRoute - CP > 선박 지나온 루트 정보 - GET /trans/rfdg/previous-route
  getCargoVesselPreiousRoute (params) {
    return Send({
      url: '/trans/rf-dg/cargo-previous-route',
      method: 'get',
      params: params
    })
  },
  // getVesselNextRoute - CP > 선박 지나갈 루트 정보 - GET /trans/rfdg/next-route
  getVesselNextRoute (params) {
    return Send({
      url: '/trans/rf-dg/next-route',
      method: 'get',
      params: params
    })
  },
  updateRfYn (param) {
    return Send({
      url: '/trans/rf-dg/rf-cntr-recv',
      method: 'post',
      params: {
        userId: param
      }
    })
  },
  getIotRfCount (param) {
    return Send({
      //url: 'http://localhost:9006/trans/rf-dg/rf-data',
      url: '/trans/rf-dg/iot-rf-target',
      method: 'get',
      params: param
    })
  }
}
